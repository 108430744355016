import React from 'react';

import { token } from '@atlaskit/tokens';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

export const BlogsIcon = ({ isSelected }: { isSelected: boolean }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	return (
		<svg
			width="20"
			height="21"
			viewBox="0 0 20 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-vc="blogs-icon"
			{...ssrPlaceholderIdProp}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.83341 6C4.91294 6 4.16675 6.74619 4.16675 7.66667V9.33333C4.16675 10.2538 4.91294 11 5.83341 11H7.50008V11.8333C7.50008 13.214 6.38079 14.3333 5.00008 14.3333C4.53984 14.3333 4.16675 14.7064 4.16675 15.1667C4.16675 15.6269 4.53984 16 5.00008 16C7.30127 16 9.16675 14.1345 9.16675 11.8333V7.66667C9.16675 6.74619 8.42056 6 7.50008 6H5.83341ZM7.50008 9.33333V7.66667H5.83341V9.33333H7.50008Z"
				fill={isSelected ? token('color.icon.selected', '#0c66e4') : token('color.icon', '#42526E')}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.1667 11H12.5001C11.5796 11 10.8334 10.2538 10.8334 9.33333V7.66667C10.8334 6.74619 11.5796 6 12.5001 6H14.1667C15.0872 6 15.8334 6.74619 15.8334 7.66667V11.8333C15.8334 14.1345 13.9679 16 11.6667 16C11.2065 16 10.8334 15.6269 10.8334 15.1667C10.8334 14.7064 11.2065 14.3333 11.6667 14.3333C13.0475 14.3333 14.1667 13.214 14.1667 11.8333V11ZM14.1667 9.33333H12.5001V7.66667H14.1667V9.33333Z"
				fill={isSelected ? token('color.icon.selected', '#0c66e4') : token('color.icon', '#42526E')}
			/>
		</svg>
	);
};
